<template>
  <div>
	  <homeBanner :bannerList="bannerList"></homeBanner>
	  
	  <div class="tckxltBox">
	    <div class="wrapper" @click="goAboutUs(twoMenu.kindId)" style="cursor: pointer;">
	  	  <div class="home-base-tit text-center">
	  	  	<div class=" base-font-36 text-bold">公司简介</div>
	  	  	<div class="base-font-20 margin-top-10 subTit">COMPANY PROFILE</div>
	  	  	<div class="homeTitLine"></div>
	  	  </div>
			<div class="tcltImage">
			  <el-image :src="twoMenu.image" fit="cover"></el-image>
			</div>
			  <div  class="base-font-16  padding-right-20 homtKXltJs"  style="line-height:2.2;text-indent: 2em;">
				<div class="ellipsis7" v-html="twoMenu.content"></div>
				
			  </div>
		  <div class="base-font-16 text-white pointer viewMoreBtn">+查看更多</div>
	    </div>
	  </div>
	  <div class="base-line-20"></div>
	  
    <!-- <div class="bg-white padding-bottom-20">
		<div class="wrapper text-center">
			<div class="home-base-tit">
				<div class=" base-font-36 text-bold">产品中心</div>
				<div class="base-font-20 text-999 margin-top-10 subTit">PRODUCT CENTER</div>
				<div class="homeTitLine"></div>
			</div>
			<div>
			
			</div>
			<div class="flex align-center justify-center">
				<div :class="proIndex==index?'base-font-17 proSortMenu checked':'base-font-17 proSortMenu'" v-for="(item,index) in proSortList" @click="getProduct(index)" :key="index">{{item.name}}</div>
			</div>
			<div class="homeProBox">
				<el-row :gutter="20">
				  <el-col :span="6" v-for="(item,index) in proList" :key="index">
					  <div class="proHomeItem"  @click="goNewsDetails(item.id)" >
						  <div class="proImage"><el-image :src="item.image" fit="contain"></el-image></div>
						  <div class="proItit ellipsis base-font-16">{{item.title}}</div>
					  </div>
				  </el-col>
				 
				</el-row>
			</div>
		</div>
    </div> -->
	
  
	
	
	<div class="wrapper padding-bottom-30">
		<div class="home-base-tit text-center">
			<div class=" base-font-36 text-bold">新闻动态</div>
			<div class="base-font-20 text-999 margin-top-10 subTit">NEWS CENTER</div>
			<div class="homeTitLine"></div>
		</div>
	  <div class="boxContent" style="box-sizing:border-box;">
	    <div class="leftNewsImage relative">
	      <div class="swiper" style="overflow: hidden;" id="homeSwiper">
	        <div class="swiper-wrapper">
	          <div  style="cursor: pointer;" class="swiper-slide" v-for="(item, index) in oneMenuBannerList" :key="index">
	            <div  class="newsItem" @click="goNewsDetails(item.id)">
	              <el-image :src="item.image" fit="cover"></el-image>
	              <div class="newsTit text-white base-font-14 flex align-center justify-between">
	                <div class="ellipsis">{{ item.title }}</div>
	              </div>
	            </div>
	          </div>
	        </div>
	        <div class="newsZsq swiper-pagination"></div>
	      </div>
	
	    </div>
	    <div class="rightNews">
	      <div>
	        <ul>
	          <li  style="cursor: pointer;" v-for="(item,index) in oneMenuList" :key="index" @click="goNewsDetails(item.id)" v-if="index<5">
				  <div class="flex align-center">
					  <div class="leftTime">
						<div class="text-666 base-font-28">{{item.showTime.substr(5,10)}}</div>
						<div class="text-999 base-font-16">{{item.showTime.substr(0,4)}}</div>
					  </div>
					  <div class="padding-left-10 homeNewsRight">
						  <div class="ellipsis base-font-28">{{ item.title }}</div>
						  <div class="base-font-16 text-999 ellipsis">{{item.depict}}</div>
					  </div>
				  </div>
	          </li>
	        </ul>
	      </div>
				<div class="margin-top-5 pointer viewMoreBtn" @click="goNewsList(menuList[menuIndex].id)" >+查看更多</div>
	    </div>
	  </div>
	</div>
	<div class="base-line-20"></div>
	
	

    <div class="wrapper padding-bottom-30 margin-top-20 margin-bottom-20 boxContent">
      <div style="box-sizing:border-box;">
        <div class="flex align-center justify-between" style="height:70px;">
		  <div class="base-font-36 base-text text-bold">成功案例</div>
          <div>
            <div @click="goNewsList('20230205235342053968709776071838')" class="flex align-center homeMoreBtn pointer">
              <i class="el-icon-more"></i>
            </div>
          </div>
        </div>
        <div class="proSwiperBox">
          <div class="swiper" id="proSwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in threeMenuList" :key="index">
                <div class="proItemSwiperBox" @click="goNewsDetailsDesc('20230205151539021064594008868268',item.id)" >
                  <div class="proItemSwiper pointer text-left">
                    <div class="tczsImage">
                      <el-image :src="item.image" fit="cover"></el-image>
                    </div>
                    <div class="base-font-28 text-center margin-top-20 base-text ellipsis">{{item.title}}</div>
                 <!--   <div class="base-font-16 margin-top-20 text-999 ellipsis"><span>{{item.source}}</span><span
                        class="margin-left-20" style="float: right">{{item.showTime}}</span></div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>





  </div>
</template>
<script>
import Swiper from 'swiper/swiper-bundle'
import {
  getHotWzContentPage,
  getOneWzContent,
  getWzContentPage,
  getWzFilePage,
  getWzImagePage,
  getWzVideoPage
} from "@/api/api";
import homeBanner from '@/page/index/head/homeBanner'
export default {
	components:{homeBanner},
  name: 'HomePage',
  data() {
    return {
	bannerList:[
		{ID:1,type:"image",img: require('@/assets/image/nbg.jpg')},
		{ID:1,type:"image",img: require('@/assets/image/nbg.jpg')},
	],
      menuList: [
        {id: '20221128141833083667837455693295', name: "公司新闻"},
        // {id: '20230205235159045073370401517997', name: "行业新闻"},
      ],
      menuIndex: 0,
      zeroMenuList:[],
      oneMenuList: [],
      oneMenuBannerList: [],
      oneMenu: {},
      twoMenu:{},
      threeMenuList:[],
      fourMenuList:[],
      fiveMenuList:[],
      logo: require('@/assets/image/logo.png'),
      bd: require('@/assets/image/bd1.jpg'),
	  proSortList:[
		  {id:"20230205235430081628160051324461",name:"砂浆产品"},
		  {id:"20230203091218087757113019830912",name:"混凝土外加剂产品"},
		  
	  ],
	  proList:[],
      proIndex:0
    }
  },
  mounted() {
    this.getProduct(0);
    this.initProSwiper()
    this.initNewsSwiper();
    this.initZdySwiper(2, 20, "#proSwiper",)
    this.initZdySwiper(4, 20, "#drwSwiper",)
    this.getzeroMenuWzContentPage();
    this.getOneMenuWzContentPage();
    this.getTwoMenuWzContentPage();
    this.getThreeMenuWzContentPage();
    this.getFourMenuWzContentPage();
    this.getFiveMenuWzContentPage();
	// this.initPubSwiper"#hotNewSwiper",2000,".hotNewsZsq"()
	this.$nextTick(
		this.initPubSwiper()
	)
  },
  methods: {

    getProduct(index) {
      this.proIndex=index;
      getWzContentPage({pageNum: 1, pageSize: 8, kindId: this.proSortList[index].id}).then(res => {
        this.proList =res.data;
      })
    },
	  
    oneMenuChange(index) {
      this.menuIndex = index;
      this.getOneMenuWzContentPage();
    },
    getzeroMenuWzContentPage() {
      getHotWzContentPage({pageNum: 1, pageSize: 10}).then(res => {
        this.zeroMenuList =res.data;
      })
    },
    getOneMenuWzContentPage() {
      getWzContentPage({pageNum: 1, pageSize: 10, kindId: this.menuList[this.menuIndex].id}).then(res => {
        this.oneMenuList = [];
        this.oneMenuBannerList = [];
        res.data.forEach((obj, index) => {
          if (index < 2) {
            this.oneMenuBannerList.push(obj);
          }
          obj.showTime = this.$moment(obj.clickTime).format('YYYY-MM-DD');
          this.oneMenuList.push(obj);
        })

      })
    },
    getTwoMenuWzContentPage() {
      getOneWzContent("20230205235228077762721476772968").then(res => {
        this.twoMenu=res.data;
      })
    },
    getThreeMenuWzContentPage() {
      getWzContentPage({pageNum: 1, pageSize: 10, kindId:'20230205235342053968709776071838'}).then(res => {
        this.threeMenuList = [];
        res.data.forEach((obj, index) => {
          obj.showTime = this.$moment(obj.clickTime).format('YYYY年MM月DD日');
          this.threeMenuList.push(obj);
        })
      })
    },
    getFourMenuWzContentPage() {
      getWzImagePage({pageNum: 1, pageSize: 10, kindId:'20230203131947061319434516639859'}).then(res => {
        this.fourMenuList = [];

        res.data.forEach(obj=>{
          this.fourMenuList.push(obj.image);
        })

      })
    },
    getFiveMenuWzContentPage() {
      getWzFilePage({pageNum: 1, pageSize: 8}).then(res => {
        this.fiveMenuList =[];
        res.data.forEach((obj, index) => {
          obj.showTime = this.$moment(obj.createTime).format('YYYY年MM月DD日');
          this.fiveMenuList.push(obj);
        })
      })
    },
    goAboutUs(kindId) {
      this.$router.push({path: '/aboutUs?kindId=' + kindId});
    },
    goNewsDetails(id) {
      this.$router.push({path: '/neswDetails?menuId=""&id=' + id});
    },
    goNewsDetailsDesc(menuId,id) {
      this.$router.push({path: '/neswDetails?menuId='+menuId+'&id=' + id});
    },
    goImageList(kindId) {
      this.$router.push({path: '/imageList?kindId=' + kindId});
    },
    goDkList(kindId) {
      this.$router.push({path: '/dkIndex?kindId=' + kindId});
    },
    goFileList() {
      this.$router.push({path: '/fileIndex'});
    },
	goNewsList(kindId){
		 this.$router.push({path: '/newsList?kindId=' + kindId});
	},
    initNewsSwiper() {
      let _self = this
      new Swiper('#homeSwiper', {
        // loop: true, // 循环模式选项
        // loopAdditionalSlides: 1,
        // autoplay: true,
        // autoplay: {
        //     disableOnTnteraction: false,
        //     delay: 3000,
        // },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        on: {
          slideChangeTransitionStart: function () {
            _self.bannerCurrent = this.activeIndex
            // console.log(_self.bannerCurrent)
            // console.log(this.activeIndex);
          },
          transitionEnd: function () {
            // console.log('过渡结束');
          },
          init: function (swiper) {
            //Swiper初始化了
            // console.log('当前的slide序号是' + this.activeIndex);//或者swiper.activeIndex，swiper与this都可指代当前swiper实例
            this.emit('transitionEnd');//在初始化时触发一次transitionEnd事件，需要先设置transitionEnd

          },
        }
      })
    },
    initProSwiper() {
      let _self = this
      new Swiper('#proSwiper', {
        // loop: true, // 循环模式选项
        // loopAdditionalSlides: 1,
        autoplay: true,
        slidesPerView: 3,
        spaceBetween: 40,
        autoplay: {
          disableOnTnteraction: false,
          delay: 10000,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // 如果需要分页器
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideChangeTransitionStart: function () {
            _self.bannerCurrent = this.activeIndex
            // console.log(_self.bannerCurrent)
            // console.log(this.activeIndex);
          },
          transitionEnd: function () {
            // console.log('过渡结束');
          },
          init: function (swiper) {
            //Swiper初始化了
            // console.log('当前的slide序号是' + this.activeIndex);//或者swiper.activeIndex，swiper与this都可指代当前swiper实例
            this.emit('transitionEnd'); //在初始化时触发一次transitionEnd事件，需要先设置transitionEnd
          },
        }
      })
    },
	initPubSwiper() {
	  let _self = this
	  new Swiper('#hotNewSwiper', {
	      // loop: true, // 循环模式选项
	      // loopAdditionalSlides: 1,
	      // autoplay: true,
	      autoplay: {
	          disableOnTnteraction: false,
	          delay:3000,
	      },
	      observer: true, //修改swiper自己或子元素时，自动初始化swiper
	      observeParents: true, //修改swiper的父元素时，自动初始化swiper
	      // 如果需要分页器
	
		  // 如果需要分页器
		  pagination: {
		      el: '.swiper-pagination',
		      clickable: true,
		  },

		})
	},


    initZdySwiper(num, jl, idName,) {
      let _self = this
      new Swiper(idName, {
        // loop: true, // 循环模式选项
        // loopAdditionalSlides: 1,
        // autoplay: true,
		  autoplay: true,
        slidesPerView: num,
        spaceBetween: jl,
        autoplay: {
          disableOnTnteraction: false,
          delay: 10000,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // 如果需要分页器
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideChangeTransitionStart: function () {
            _self.bannerCurrent = this.activeIndex
            // console.log(_self.bannerCurrent)
            // console.log(this.activeIndex);
          },
          transitionEnd: function () {
            // console.log('过渡结束');
          },
          init: function (swiper) {
            //Swiper初始化了
            // console.log('当前的slide序号是' + this.activeIndex);//或者swiper.activeIndex，swiper与this都可指代当前swiper实例
            this.emit('transitionEnd'); //在初始化时触发一次transitionEnd事件，需要先设置transitionEnd
          },
        }
      })
    },
    // 我的产品菜单点击
    ourProMenuClick(index) {
      this.ourProItemCurrent = index
      this.proSwiper = this.proSwiperData[index].list
    },
    //解决方案划过
    jjfaItemMove(index) {
      this.jjfaItemCurrent = index
    }
  }

}
</script>
<style>
	
	.home-base-tit{padding:35px 0 20px 0}
	.home-base-tit .subTit{letter-spacing:7px;}
	.home-base-tit .homeTitLine{width:35px;height:4px;background:#e85c00;border-radius:2px;margin:10px auto;}
	.proSortMenu{padding:9px 23px;border:1px solid #253783;margin:10px 30px;cursor: pointer;}
  .proSortMenu.checked,.proSortMenu:hover{background: #253783;color:#FFFFFF}
	.homeProBox{border-top:1px solid #efefef;padding-bottom: 30px;margin-top:20px}
	.homeProBox .proHomeItem{width: 100%;height:auto;background:#FFFFFF;border: 1px solid #eeeeee;margin:20px auto 0 auto;cursor: pointer;}
	.homeProBox .proHomeItem .proImage{width:100%;height:300px;background: #FFFFFF;overflow: hidden;}
	.homeProBox .proHomeItem .proImage .el-image{width:100%;height:300px;
	-webkit-transform:scale(1);
			    -moz-transform:scale(1);
			    -ms-transfrom:scale(1);
			    -o-transform:scale(1);
			    transform:scale(1);
			transition: transform 0.09s;
	-webkit-transition: transform 0.09s;
	}
	.homeProBox .proHomeItem .proItit{height:60px;line-height:60px;text-align: center;background:#eeeeee;color:#2e2e2e}
	.homeProBox .proHomeItem:hover{border: 1px solid #253783;}
	.homeProBox .proHomeItem:hover .proItit{background: #253783;color:#FFFFFF;}
	.homeProBox .proHomeItem:hover .proImage .el-image{
		-webkit-transform:scale(1.1);
		    -moz-transform:scale(1.1);
		    -ms-transfrom:scale(1.1);
		    -o-transform:scale(1.1);
		    transform:scale(1.1);
		transition: transform 0.09s;
  -webkit-transition: transform 0.09s;}
.zcwjxzBtn {
  width: 77px;
  height: 30px;
  line-height: 30px;
  border-radius: 16px;
  color: #ffffff;
  font-size: 16px;
  background: #253783;
  text-align: center;
  cursor: pointer
}

.zcwnItem1 {
  background: #253783;
  color: #ffffff;
  padding: 20px;
  box-sizing: border-box;
}

.zcwnItem2 {
  position: relative;
  background: #F8F8F8;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 15px;
}

.zcwnItem2::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #2D8CDB;
}

.tczsImage, .tczsImage .el-image {
  width:100%;
  height: 220px
}

.tcltImage {
  width: 100%;
  height: 358px;
}

.tcltImage .el-image {
  width: 100%;
  height: 358px;
}

.tckxltBox {
  padding:0 30px;box-sizing: border-box;
 
}
.boxContent{padding:0 30px;box-sizing: border-box;}

.baseNews {
	width:1180px;
  padding: 40px 30px;
  box-sizing: border-box;
  color: #11558A;
}

.leftNewsImage {
  width:100%;
  height: 385px;
  background: #ffffff
}

.leftNewsImage .swiper .swiper-slide .newsItem {
  width: 100%;
  height: 385px;
  position: relative;
}

.newsItem .newsTit {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: rgba(40, 18, 31, 0.6);
  padding: 0 15px;
  box-sizing: border-box;;
  z-index: 999;
  position: absolute;
  left: 0;
  bottom: 0;
}

.leftNewsImage .swiper .swiper-slide .newsItem .el-image {
  width: 100%;
  height: 385px;
}

.newsZsq {
  position: absolute;
  bottom: 0 !important;
  z-index: 1000;
  width: 90px !important;
  height: 20px !important;
  left: 460px !important;
  bottom: 10px !important;
}

.rightNews {
  width: 100%;
  height: auto;
}

.rightNews .menuList {
  color: #28121F;
  font-size: 22px;
  font-weight: bold;
}

.homeNewsRight{width:590px;}
.rightNews .menuList .menItem {
  height: 46px;
  line-height: 46px;
  padding: 0 20px;
  cursor: pointer;
  border: 2px solid #F8F8F8;
   border-radius: 25px;
  
}

.rightNews .menuList .menItem.checked {
  border: 2px solid;
  border-radius: 25px;
  color: #11558A
}

.rightNews .menuList .menItem + .menItem {
  margin-left: 5px;
}

.rightNews ul li {
  position: relative;
  height:90px;
  line-height:1.5;
  font-size: 18px;
  color: #28121F;
  padding: 0 0 0 0;
  box-sizing: border-box;
  list-style-type: none;
  margin-top: 10px;
}



.rightNews ul li .newsTime {
  position: absolute;
  height: 45px;
  line-height: 45px;
  right: 0;
  top: 0;
}
.rightNews ul li .leftTime{padding-right:15px;text-align:right;font-weight:lighter;flex-shrink:0;border-right:1px solid #dddddd;}
.rightNews ul li:hover{color: #0071ef;}
.homeBlock {
  padding: 80px 0
}

.homeBlockTwo {
  background: #f8fafd url('../../assets/image/homeBlcokBg2.jpg') center no-repeat;
  background-size: cover;
  position: relative;
}


.homeBlockOne {
  background: #f8fafd url('../../assets/image/homebg.png') center no-repeat;
  background-size: cover;
}

.homeProItemOut {
  height: 239px;
  padding: 20px 0 0 0;
  transition: padding 0.09s;
  box-sizing: border-box;
  -webkit-transition: padding 0.09s;
}

.homeProItem {
  padding: 35px 20px;
  line-height: 1.7;
  border-radius: 3px;
  box-shadow: 0 0 20px #f6f6f6;
  height: 219px;
  box-sizing: border-box;
  overflow: hidden;
  transition: padding 0.9s;
  -webkit-transition: padding 0.9s;
}

.homeProItemOut:hover {
  height: 239px;
  padding: 10px 0 0 0;
  transition: padding 0.09s;
  -webkit-transition: padding 0.09s;
  box-shadow: 0 0 20px #eeeeee;

}

.homeProItemOut:hover .homeProItem {
  height: 239px;
  transition: height 0.09s;
  -webkit-transition: height 0.09s;
}

.homeProItem .icon {
  width: 36px;
  height: 36px;
  border-radius: 5px;
  overflow: hidden;
}

.homeProItem .proTag {
  font-size: 12px;
  color: #0071ef;
  border: 1px solid;
  padding: 2px 3px;
  border-radius: 3px;
  line-height: 1;
  margin-left: 3px;
}

.homeProItem .btn {
  height: 38px;
  line-height: 38px;
  width: 104px;
  text-align: center;
  border-radius: 2px;
  opacity: 0;
  transition: padding 0.09s;
  -webkit-transition: padding 0.09s;

}

.homeProItem:hover .btn {
  opacity: 1;
  transition: padding 0.09s;
  -webkit-transition: padding 0.09s;
}

.homeProItem:hover {
  padding: 25px 20px;
  transition: padding 0.09s;
  -webkit-transition: padding 0.09s;
}

.homeProItem:hover .msHeight {
  height: 70px;
  transition: padding 0.09s;
  -webkit-transition: padding 0.09s;
}

.homeProItem:hover .msHeight {
  height: 50px;
  transition: height 0.09s;
  -webkit-transition: height 0.09s;
}


.yxhzzxBtn {
  padding: 17px 0;
  line-height: 1;
  border: 2px solid;
  border-radius: 3px;
  width: 20%;
  margin: 30px auto 0 auto;
  color: #3ba20a;
  font-size: 18px
}

.yxhzzxBtn:hover {
  background: #3ba20a;
  color: #FFFFFF;
  border-color: #3ba20a;
}


.proItemSwiperBox {
  width: 100%;
}


.proItemSwiper {
  width: 100%;
  border-radius: 10px;
  height: 300px;
  box-sizing: border-box;
  line-height: 1;
}

.proItemSwiperBox .box1 {
  display: block
}


.proItemSwiper .proItemImage {
  width: 130px;
  height: 130px;
  margin: 0 auto;
  border-radius: 10px;
}

.proItemSwiper .proItemImage .el-image {
  width: 130px;
  height: 130px;
}

.proSwiperBox {
}

.proSwiperBox .swiper {
  width: 100%;
  height: 100%;
}

.proSwiperBox .swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.proSwiperBox .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.proItemYs {
  padding: 20px 0;
}

.proItemYs + .proItemYs {
  border-top: 1px solid #eeeeee;
}

.homeProSwiperBtn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: none;
  outline: none;
}

.homeProSwiperBtn:after {
  font-size: 20px;
  color: #0071ef;
  font-weight: bold;

}

#hotNewSwiper .swiper-wrapper{width:1180px!important;}
.swiper-pagination.hotNewsZsq {
    bottom: 15px !important;
    z-index: 99;
}

.hotNewsZsq .swiper-pagination-bullet {
    width: 20px;
    height: 3px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0;
    opacity: 1;
    transition: width 0.9s;
    -webkit-transition: width 0.9s;
	outline: none;
}

.hotNewsZsq .swiper-pagination-bullet-active {
    width: 28px;
    height: 3px;
    border-radius: 0;
    background: #0071ef;
    transition: width 0.9s;
    -webkit-transition: width 0.9s;
	outline: none;
}
.homtKXltJs,.homtKXltJs p{background:none!important;}
.viewMoreBtn{padding:20px 0;text-align: center;font-size:26px;color:#0081ff;border-top:1px dashed #dddddd;}
</style>
