<template>
    <div>
        <div class="banner">
            <div class="swiper" style="overflow: hidden;" id="homeSwiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in bannerList" :key="index">
                        <div class="bannerItem" v-if="item.type == 'image'">
                            <div class="Bannerabsolute">
                                <div class="banerConter text-left text-white"
                                    :class="(bannerCurrent == index ? 'bannerShow' : 'bannerHide')">
                                    <div class="base-font-48 ellipsis2 bannerTitle">{{ item.title }}</div>
                                    <div class="base-font-20 margin-top-20 ellipsis2 bannerContent"
                                        style="opacity:0.7;width:450px;">{{
                                                item.content
                                        }}</div>
                                    <div class="base-font-14 margin-top-10 ellipsis2 bannerMs"
                                        style="opacity:0.7;width:450px;">{{
                                                item.ms
                                        }}
                                    </div>
                                    <!-- <div class="bannerViewBtn text-center text-white base-font-16 pointer">立即查看</div> -->
                                </div>
                            </div>
                            <el-image :src="item.img" fit="cover"></el-image>
                        </div>
                       
                    </div>
                </div>
                <!-- 如果需要分页器 -->
                <div class="swiper-pagination homePagination"></div>
                <!-- 如果需要导航按钮 -->
                <div class="swiper-button-prev homeSwiperBtn"></div>
                <div class="swiper-button-next homeSwiperBtn"></div>
            </div>
        </div>
        <!-- 视频弹窗 -->


    </div>
</template>
<script>
import Swiper from 'swiper/swiper-bundle'
export default {
    props: {
        bannerList: {
            type: Array,
        }
    },
    data() {
        return {
            bannerCurrent: 0,
         
        }
    },
    mounted() {
        this.initSwiper()
    },
    methods: {
        initSwiper() {
            let _self = this
            new Swiper('#homeSwiper', {
                // loop: true, // 循环模式选项
                // loopAdditionalSlides: 1,
                // autoplay: true,
                autoplay: {
                    disableOnTnteraction: false,
                    delay:5000,
                },
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, //修改swiper的父元素时，自动初始化swiper
                // 如果需要分页器
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    slideChangeTransitionStart: function () {
                        _self.bannerCurrent = this.activeIndex
                        // console.log(_self.bannerCurrent)
                        // console.log(this.activeIndex);
                    },
                    transitionEnd: function () {
                        // console.log('过渡结束');
                    },
                    init: function (swiper) {
                        //Swiper初始化了
                        // console.log('当前的slide序号是' + this.activeIndex);//或者swiper.activeIndex，swiper与this都可指代当前swiper实例
                        this.emit('transitionEnd');//在初始化时触发一次transitionEnd事件，需要先设置transitionEnd

                    },
                }
            })
        }
    }

}
</script>
<style>
.banner .swiper .swiper-slide .bannerItem {
    width: 100%;
    height: 560px;
    position: relative;
}

.bannerItem .Bannerabsolute {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    z-index: 99;
}

.bannerItem .BannerVideo {
    position: absolute;
    width: 100%;
    height: 560px;
    left: 0;
    top: 64px;
    right: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.05);
}

.bannerItem .BannerVideo .viewVideo {
    width: 80px;
    height: 80px;
    margin: 200px auto;
    border: 2px solid rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    cursor: pointer;
    line-height: 100px;
    background: -webkit-linear-gradient(left top, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    background: -o-linear-gradient(bottom right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    background: -moz-linear-gradient(bottom right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
}

.bannerItem .BannerVideo .viewVideo:hover {
    background: #0071ef;
    border-color: #0071ef;
}

.bannerItem .banerConter {
    width: 100%;
    height:323px;
    margin:0 auto;
}


.bannerHide .banerConter {
    width: 100%;
    height:323px;
    margin:0 auto;
}


.bannerHide .bannerTitle {
    margin-left: 150px;
    transition: margin-left 0.9s;
    -webkit-transition: margin-left 0.9s;
}

.bannerHide .bannerContent {
    margin-left: 160px;
    transition: margin-left 1.2s;
    -webkit-transition: margin-left 1.2s;
}

.bannerHide .bannerMs {
    margin-left: 170px;
    transition: margin-left 1.3s;
    -webkit-transition: margin-left 1.3s;
}

.bannerHide .bannerViewBtn {
    margin-left: 180px;
    transition: margin-left 1.5s;
    -webkit-transition: margin-left 1.5s;
}

.bannerHide .banerConter {
    width: 1180px;
    height: 450px;
    margin: 150px auto 0 auto;
}


.bannerShow .bannerTitle {
    margin-left: 0;
    transition: margin-left 0.9s;
    -webkit-transition: margin-left 0.9s;
}

.bannerShow .bannerContent {
    margin-left: 0;
    transition: margin-left 1.2s;
    -webkit-transition: margin-left 1.2s;
}

.bannerShow .bannerMs {
    margin-left: 0;
    transition: margin-left 1.3s;
    -webkit-transition: margin-left 1.3s;
}

.bannerShow .bannerViewBtn {
    margin-left: 0;
    transition: margin-left 1.5s;
    -webkit-transition: margin-left 1.5s;
}


.banner .swiper .swiper-slide .bannerItem .el-image {
    width: 100%;
    height:323px;
}

.bannerViewBtn {
    width: 132px;
    height: 48px;
    background: #0071ef;
    border-radius: 3px;
    line-height: 48px;
    margin-top: 35px;
}

.swiper-pagination.homePagination {
    bottom: 15px !important;
    z-index: 99;
}

.homePagination .swiper-pagination-bullet {
    width: 20px;
    height: 3px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0;
    opacity: 1;
    transition: width 0.9s;
    -webkit-transition: width 0.9s;
}

.homePagination .swiper-pagination-bullet-active {
    width: 28px;
    height: 3px;
    border-radius: 0;
    background: #0071ef;
    transition: width 0.9s;
    -webkit-transition: width 0.9s;
}

.homeVideoBox {
    widows: 560px;
}

.homeVideoBox video {
    width: 100%;
    height: auto;
}

.homeVideoModal {
    background: rgba(0, 0, 0, 0.3);
}

.homeVideoModal .el-dialog {
    background: none;
    box-shadow: none;
}

.homeVideoModal .el-dialog--center .el-dialog__body {
    padding: 0;
}

.homeVideoModal .el-dialog__headerbtn {
    right: 0;
    top: 0;
}

.homeVideoModal .el-dialog__headerbtn .el-dialog__close {
    color: #FFFFFF;
    font-size: 28px;
}

.homeSwiperBtn{width:70px;height:70px;border-radius:50%;background:rgba(255, 255, 255, 0.1);outline: none;color:#FFFFFF;}
</style>
